import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './ScoreScaleModal.scss';

import PopupOverlay from '../PopupOverlay/PopupOverlay';
import Button from '../Button/Button';

function ScoreScaleModal({ isOpen, closeModal, content }) {
  const SCALE_TABLE_ROWS = [
    ['C2', '171-200'],
    ['C1', '141-170'],
    ['B2', '111-140'],
    ['B1', '81-110'],
    ['A2', '51-80'],
    ['A1', '21-50'],
    ['Pre-A1', '1-20']
  ];

  return (
    <div className={`${styles.scoreScaleWrapper} ${isOpen ? styles.open : ''}`} aria-hidden={!isOpen}>
      <PopupOverlay
        id="scoreScaleOverlayId"
        visible={isOpen}
        customClassName={classnames([styles.menu], [styles.scoreScale])}
        onTogglePopup={closeModal}
        buttonElementId="scoreScaleButton"
      >
        <div>
          <Button type="CLOSE_BOLD_HOVER" text={content.scale_close_button} onClick={closeModal} />
          <h3>{content.scale_title}</h3>
          <p>{content.scale_description}</p>
          <table className={styles.scaleTable}>
            <thead>
              <tr>
                <th>{content.scale_table_head_score}</th>
                <th>{content.scale_table_head_cefr}</th>
              </tr>
            </thead>
            <tbody>
              {SCALE_TABLE_ROWS.map(([key, value], index) => (
                <tr key={index}>
                  <td>{value}</td>
                  <td>{key}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </PopupOverlay>
    </div>
  );
}

ScoreScaleModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired
};

export default ScoreScaleModal;
