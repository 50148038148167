import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';
import styles from './ErrorPage.scss';
import { RvsLayoutConstants } from '../../../globals/rvsConstants';

import withLocalizedContent from '../../../language/withLocalizedContent';

import Button, { buttonTypes } from '../../../components/Button/Button';
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import forceAcceptBanner from '../../../utils/cookieBanner/forceAcceptBanner';

function ErrorPage({ localizedContent: { errorPage: content } }) {
  forceAcceptBanner();

  return (
    <div>
      <div className={styles.messageContainer}>
        <Helmet title={content.error_page_head} />
        <SVGIcon glyph={GLYPHS.ICON_ERROR_CIRCLE} />
        <h1>{content.bot_message2}</h1>
        <div className={styles.explainText}>
          {content.bot_message1}{' '}
          <a href={content.explain_link} className={styles.inlineLink}>
            {content.explain_text2}
          </a>{' '}
          {content.bot_message3}
        </div>
        <div className={styles.actions}>
          <div className={styles.centeredButton}>
            <Button
              type={buttonTypes.PRIMARY}
              text={content.bot_button}
              onClick={() => {
                window.location.replace(RvsLayoutConstants.PATH_NAMES.HOME_PAGE);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

ErrorPage.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default compose(withLocalizedContent('errorPage'))(ErrorPage);
