import React, { Component } from 'react';
import styles from './RvsLowerRow.scss';

import cmsContent from '../../../../utils/cmsContent.js';
import CertificateUpload from '../../../../components/CertificateUpload/CertificateUpload';
import InformationBox from '../../../../components/InformationBox/InformationBox';
import Button from '../../../../components/Button/Button';
import { RvsLayoutConstants } from '../../../../globals/rvsConstants';

import { featureIsEnabled } from '../../../../globals/envSettings';

export default class RvsLowerRow extends Component {
  constructor() {
    super();
    this.state = { CMS: {} };
  }

  componentWillMount() {
    this.setState({ CMS: cmsContent.rvsHomepage || {} });
  }

  componentWillReceiveProps() {
    this.setState({ CMS: cmsContent.rvsHomepage || {} });
  }

  render() {
    const { CMS } = this.state;
    const MAINTENANCE_MODE = !featureIsEnabled('allow-certificate-upload');

    return (
      <div className={styles.RvsHomeLowerRow}>
        {MAINTENANCE_MODE ? (
          <div className={styles.RvsHomeLowerRowContainer}>
            <div className={styles.MaintenanceMessageContainer}>
              {/* eslint-disable-next-line react/no-danger */}
              <p dangerouslySetInnerHTML={{ __html: CMS.maintenance_message }} />
            </div>
            {/* eslint-disable-next-line react/no-danger */}
            <p className={styles.Recaptcha} dangerouslySetInnerHTML={{ __html: CMS.recaptcha_html }} />
          </div>
        ) : (
          <div className={styles.RvsHomeLowerRowContainer}>
            <InformationBox
              title={CMS.legacy_certificate_box_title}
              firstMessage={CMS.legacy_certificate_box_first_message}
              secondMessage={CMS.legacy_certificate_box_second_message}
              isFirstHtml
              isSecondHtml
              customClassName={styles.InformationBox}
            />
            <CertificateUpload />
            <div className={styles.ManualInputContainer}>
              <p>{CMS.manual_input_text}</p>
              <Button text={CMS.manual_input_button_text} to={RvsLayoutConstants.PATH_NAMES.MANUAL_FORM} />
            </div>
            <div className={styles.TermsContainer}>
              <p>{CMS.terms_description}</p>
            </div>
            {/* eslint-disable-next-line react/no-danger */}
            <p className={styles.Recaptcha} dangerouslySetInnerHTML={{ __html: CMS.recaptcha_html }} />
          </div>
        )}
      </div>
    );
  }
}
