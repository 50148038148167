import { RvsLayoutConstants } from '../../../globals/rvsConstants';

const evaluateLayoutProps = ({ pathname }) => {
  const layoutProps = {
    pageTitle: 'RVS',
    headerProps: {
      isEmbedded: false,
      hideRegisterLink: false,
      hideSignInLink: false,
      hideWidgetDropDown: false,
      hideSubHeader: true,
      disableLogoLink: false,
      hideHelpLink: false,
      hideMyAccountText: true,
      hideBurgerMenu: false,
      darkLayout: false,
      hideLanguageSelector: false
    },
    footerProps: {
      hideUpperFooter: true,
      darkLayout: false
    }
  };

  if (pathname === RvsLayoutConstants.PATH_NAMES.HOME_PAGE) {
    layoutProps.headerProps.darkLayout = true;
    layoutProps.footerProps.hideUpperFooter = false;
    layoutProps.footerProps.darkLayout = true;
  }

  if (pathname.startsWith(`${RvsLayoutConstants.PATH_NAMES.SUPPORT}/`)) {
    layoutProps.headerProps.darkLayout = true;
    layoutProps.headerProps.hideHelpLink = true;
    layoutProps.footerProps.hideUpperFooter = true;
    layoutProps.footerProps.darkLayout = true;
  }

  return layoutProps;
};

export default evaluateLayoutProps;
