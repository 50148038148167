import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

// Home page components
import RvsTopRow from './parts/RvsTopRow/RvsTopRow';

import styles from './RvsHomepage.scss';

import cmsContent from '../../utils/cmsContent.js';
import RvsLowerRow from './parts/RvsLowerRow/RvsLowerRow';

import { clearAllCertChannels } from '../../redux/sagas/utils/rvs/certificateChannels';

export default class RvsHomepage extends Component {
  constructor() {
    super();
    this.state = { CMS: {} };
  }

  componentWillMount() {
    this.setState({ CMS: cmsContent.rvsHomepage || {} });
    clearAllCertChannels();
  }

  componentWillReceiveProps() {
    this.setState({ CMS: cmsContent.rvsHomepage || {} });
  }

  render() {
    const { CMS } = this.state;

    return (
      <div className={styles.RvsHomePageContainer}>
        <Helmet title={CMS.pageTitle} />
        <RvsTopRow />
        <RvsLowerRow />
      </div>
    );
  }
}
