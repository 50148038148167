import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Styles
import styles from './RvsSupport.scss';
// Constants
import RvsSupportConstants from './RvsSupportConstants';
// Components
import Link from '../../components/Link/Link';
import RvsSupportIndexItem from './RvsSupportIndexItem';
import { getLanguage } from '../../utils/manageSelectorLanguage';

function articleLinkItem(article, index = 0, currentLanguage) {
  return (
    <li key={article.name}>
      <Link tabIndex={index} to={article.path} className={styles.hubSupportArticleLink}>
        {currentLanguage === 'es' ? article.name_es : article.name}
      </Link>
      <div className={styles.hubSupportLinkBorder} />
    </li>
  );
}

function RvsSupportIndex({ showOrganizations }) {
  const [articlesOpen, setArticlesOpen] = useState(new Set());

  const updateArticlesOpen = idArticle => {
    const currentArticlesOpen = new Set(articlesOpen);
    if (currentArticlesOpen.has(idArticle)) {
      currentArticlesOpen.delete(idArticle);
    } else {
      currentArticlesOpen.add(idArticle);
    }

    setArticlesOpen(currentArticlesOpen);
  };

  const currentLanguage = getLanguage();

  return (
    <div className={styles.hubSupport}>
      {RvsSupportConstants(showOrganizations).map(
        (item, index) =>
          item.showSection && (
            <div key={index} className={styles.hubSupportIndex} id={item.dom_id}>
              <div id={`header-${index}`} className={styles.hubSupportTitle}>
                {currentLanguage === 'es' ? item.title_es : item.title}
              </div>
              <div className={styles.hubSupportTitleLinkBorder} />
              <div className={styles.hubSupportArticles}>
                <ul aria-labelledby={`header-${index}`}>
                  {item.links.map(
                    (article, idx) =>
                      (article.showArticle || article.showArticle === undefined) &&
                      (!Object.prototype.hasOwnProperty.call(article, 'links') ? (
                        articleLinkItem(article, 0, currentLanguage)
                      ) : (
                        <RvsSupportIndexItem
                          article={article}
                          onToggle={() => updateArticlesOpen(idx)}
                          active={articlesOpen.has(idx)}
                          articleLinkItem={articleLinkItem}
                          index={idx}
                        />
                      ))
                  )}
                </ul>
              </div>
            </div>
          )
      )}
    </div>
  );
}

RvsSupportIndex.propTypes = {
  showOrganizations: PropTypes.bool.isRequired
};

export default RvsSupportIndex;
