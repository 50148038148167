import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Link from '../../components/Link/Link';
import Thumbnail, { SIZES as thumbnailSizes } from '../../components/Thumbnail/Thumbnail';
import { GLYPHS } from '../../components/SVGIcon/SVGIcon';
import style from './RvsSupportIndexItem.scss';

function RvsSupportIndexItem({ articleLinkItem, article, active, onToggle, index }) {
  const [clicked, setClicked] = useState(false);
  const listTitles = useRef();
  const dimension = active && listTitles.current !== undefined ? listTitles.current.scrollHeight : '0px';

  function handleClick() {
    setClicked(!clicked);
    onToggle();
  }

  return (
    <li className={style.hubSupportIndexItem}>
      <button
        type="button"
        className={style.dropdownButtonContainer}
        // eslint-disable-next-line react/jsx-no-bind
        onClick={handleClick}
        onKeyPress={e => e.keyCode === 13 && handleClick}
        aria-controls={`sect-${index}`}
        id={`accordion-${index}`}
        aria-label={`${article.title} ${clicked ? 'expanded' : 'collapsed'}`}
      >
        <span className={classnames([style.thumbnailContainer], { [style.thumbnailActive]: active })}>
          <Thumbnail glyph={GLYPHS.CHEVRON_DOWN_THICK} size={thumbnailSizes.SMALL} />
          <Link>
            <div className={style.buttonName}>{article.title}</div>
          </Link>
        </span>
      </button>
      <div className={style.hubSupportTitleLinkBorder} />
      <ul
        id={`sect-${index}`}
        aria-labelledby={`accordion-${index}`}
        ref={listTitles}
        style={{ height: dimension }}
        className={active ? style.listArticlesOpen : style.listArticlesClosed}
      >
        {article.links.map(item => articleLinkItem(item, clicked ? 0 : -1))}
      </ul>
    </li>
  );
}

export default RvsSupportIndexItem;

RvsSupportIndexItem.propTypes = {
  active: PropTypes.bool,
  article: PropTypes.object,
  title: PropTypes.string,
  links: PropTypes.array,
  articleLinkItem: PropTypes.func,
  onToggle: PropTypes.func,
  index: PropTypes.number
};
