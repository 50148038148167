/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from '../../components/ContactUsOlb/ContactUsOlb.scss';
import withLocalizedContent from '../../language/withLocalizedContent';
// Components
import ContactUsDetails from '../../components/ContactUsDetails/ContactUsDetails';
import { getLanguage } from '../../utils/manageSelectorLanguage';
import forceAcceptBanner from '../../utils/cookieBanner/forceAcceptBanner';

class RvsSupportContactUs extends Component {
  render() {
    forceAcceptBanner();
    const {
      localizedContent: { rvsContactUs: localizedContent }
    } = this.props;

    const currentLanguage = getLanguage();

    const spanishContent = JSON.parse(JSON.stringify(localizedContent));
    spanishContent.contact_information_availability_text = localizedContent.contact_information_availability_text_spain;
    spanishContent.contact_information_telephone_number = localizedContent.contact_information_telephone_number_spain;
    spanishContent.contact_information_telephone_text = localizedContent.contact_information_telephone_text_spain;
    spanishContent.contact_information_email = localizedContent.contact_information_email_spain;

    return (
      <div className="grid">
        <div className={classnames('row')}>
          {currentLanguage === 'es' ? (
            <>
              <div className={classnames('col md4', styles.borderedCol)}>
                <div className={styles.pad2}>
                  <em className={styles.blueHeader}>{localizedContent.contact_information_body_text_title_spain}</em>
                  <ContactUsDetails glossary={spanishContent} hideAddress showChatText />
                </div>
              </div>
              <div className={classnames('col md4', styles.borderedCol)}>
                <div className={styles.pad2}>
                  <em className={styles.blueHeader}>{localizedContent.contact_information_body_text_title}</em>
                  <ContactUsDetails glossary={localizedContent} />
                </div>
              </div>
            </>
          ) : (
            <div className={classnames('col md4', styles.borderedCol)}>
              <div className={styles.pad2}>
                <em className={styles.blueHeader}>{localizedContent.contact_information_body_text_title}</em>
                <ContactUsDetails glossary={localizedContent} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

RvsSupportContactUs.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('rvsContactUs')(RvsSupportContactUs);
