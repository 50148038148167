import PersistentStorage from '../storage/Persistent';

const cookieKey = 'cookiesAccepted';
const cookieBannerAcceptId = 'cookieBannerClose';

const forceAcceptBanner = () => {
  const cookiesStorage = new PersistentStorage(cookieKey);
  if (!cookiesStorage.exists()) {
    cookiesStorage.set(true);
    setTimeout(() => {
      const closeButton = document.getElementById(cookieBannerAcceptId);
      // Because the cookie banner is a separate component we cannot be sure the button to close
      // it will render fast enough so a delay to auto accepting (so the banner visually goes away)
      // is needed
      if (closeButton) {
        closeButton.click();
      }
    }, '3000');
  }
};

export default forceAcceptBanner;
