import React from 'react';
import RvsSpanishChat from './RvsSpanishChat';
import { getLanguage } from '../../utils/manageSelectorLanguage';

const getSpanishChat = () => <RvsSpanishChat />;

const getChatforLanguage = langCode => {
  switch (langCode) {
    case 'es':
      return getSpanishChat();
    default:
      return null;
  }
};

function RvsLiveChat() {
  const langCode = getLanguage();
  return <>{getChatforLanguage(langCode)}</>;
}

export default RvsLiveChat;
