import React from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';

import LocationChangeListener from './locationChangeListener';
import ScrollToTop from './RVSLayout/ScrollToTop.js';

import RVSLayout from './RVSLayout';
import NotFoundView from '../views/errors/NotFoundView';
import ValidCertificatePage from '../routes/Rvs/ValidCertificatePage/ValidCertificatePage';
import InvalidCertificatePage from '../routes/Rvs/InvalidCertificatePage/InvalidCertificatePage';
import ErrorPage from '../routes/Rvs/ErrorPage/ErrorPage';
import SupportMessagePage from '../routes/Rvs/SupportMessagePage/SupportMessagePage';

// Blank page
import BlankPage from '../routes/BlankPage/BlankPage';
import RvsFormPage from '../routes/RvsFormPage/RvsFormPage';

import RvsSupportPage from '../routes/RvsSupport/RvsSupportPage';
import SupportArticlePage from '../routes/Support/SupportArticle/SupportArticlePage';

function RouteManager() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <LocationChangeListener>
        <Switch>
          {/* Blank page for loading core assets only, used for OUP.VIEW.embed prior to .gotoPage(...) */}
          <Route path="blank">
            <BlankPage />
          </Route>

          {/* / path will load the rvs layout which will load the actuall home page if it does not have any children */}
          <Route exact path="/">
            <RVSLayout />
          </Route>

          <Route path="/valid">
            <RVSLayout>
              <ValidCertificatePage />
            </RVSLayout>
          </Route>

          <Route path="/invalid">
            <RVSLayout>
              <InvalidCertificatePage />
            </RVSLayout>
          </Route>

          <Route path="/supportmessage">
            <RVSLayout>
              <SupportMessagePage />
            </RVSLayout>
          </Route>

          <Route path="/error">
            <RVSLayout>
              <ErrorPage />
            </RVSLayout>
          </Route>

          <Route path="/form">
            <RVSLayout>
              <RvsFormPage />
            </RVSLayout>
          </Route>

          {/* Support pages */}
          <Route path="/support/articles/:pageName?">
            <RVSLayout>
              <SupportArticlePage />
            </RVSLayout>
          </Route>

          <Route path="/support/:tabName?">
            <RVSLayout>
              <RvsSupportPage />
            </RVSLayout>
          </Route>

          {/* 404 */}
          <Route path="*">
            <RVSLayout>
              <NotFoundView />
            </RVSLayout>
          </Route>
        </Switch>
      </LocationChangeListener>
    </BrowserRouter>
  );
}

export default RouteManager;
