import React from 'react';
import { Helmet } from 'react-helmet';
import styles from './RvsFormPage.scss';
import { readRetryChannel } from '../../redux/sagas/utils/rvs/certificateChannels';
import RvsForm from '../../components/RvsForm/RvsForm';
import forceAcceptBanner from '../../utils/cookieBanner/forceAcceptBanner';
import cmsContent from '../../utils/cmsContent.js';

function RvsFormPage() {
  const defaultData = {
    testTakerName: '',
    dateOfBirth: '',
    testTakerNumber: '',
    certificateReferenceNumber: '',
    certificateType: '0',
    overallScore: '',
    overallCefrLevel: '',
    cefrLevels: [],
    speakingScore: '',
    speakingCefrLevel: '',
    speakingDateTaken: '',
    listeningScore: '',
    listeningCefrLevel: '',
    listeningDateTaken: '',
    readingScore: '',
    readingCefrLevel: '',
    readingDateTaken: '',
    writingScore: '',
    writingCefrLevel: '',
    writingDateTaken: ''
  };

  const formData = readRetryChannel();

  const props = {
    data: formData || defaultData,
    disabled: false,
    readOnly: false,
    customClassName: styles.form
  };

  const CMS = cmsContent.rvsFormPage || {};

  forceAcceptBanner();

  return (
    <div className={styles.container}>
      <Helmet title={CMS.page_form_helmet} />
      <RvsForm {...props} />
    </div>
  );
}

export default RvsFormPage;
