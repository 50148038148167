import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useParams, useLocation } from 'react-router-dom';

import withLocalizedContent from '../../language/withLocalizedContent';
// Redux
import { closeEditMyAccountModal } from '../../redux/actions/hubUi';
// Styles
import styles from './RvsSupport.scss';
// Constants
import ENTITY_TYPES from '../../globals/entityTypes';
import colors from '../../globals/colors.js';
// Components
import { EntityPageHeader } from '../../components';
import UrlControlledTabs from '../../components/UrlControlledTabs/UrlControlledTabs';
import RvsSupportIndex from './RvsSupportIndex';
import RvsSupportContactUs from './RvsSupportContactUs';
import gtmPageview from '../../utils/gtm/pageview';
import MyProfileEditPanel from '../../panels/MyProfileEditPanel';
import forceAcceptBanner from '../../utils/cookieBanner/forceAcceptBanner';

function RvsSupportPage({
  localizedContent: { hubGlossary: hubContent },
  editAccountModalOpen,
  closeEditAccountModal
}) {
  forceAcceptBanner();

  const params = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const panelName = params.panelName;
  return (
    <div className={styles.hubSupport}>
      <Helmet
        title={hubContent.support_page_title}
        titleTemplate={hubContent.page_title_template}
        onChangeClientState={() => gtmPageview()}
      />
      <EntityPageHeader
        entityType={ENTITY_TYPES.SUPPORT}
        entityTitle={hubContent.support_page_title_header}
        displayEditButton={false}
      />
      <UrlControlledTabs
        tabName={params.tabName}
        pathname={location.pathname}
        backgroundColor={colors.SUPPORT}
        items={[
          ...(params.tabName === 'index' || !query.get('platform')
            ? [
                {
                  urlName: 'index',
                  tabText: hubContent.support_help_and_faq_text,
                  color: colors.LEARNERS,
                  panelContent: <RvsSupportIndex showOrganizations pathname={location.pathname} panelName={panelName} />
                }
              ]
            : []),
          {
            urlName: 'contact-us',
            tabText: hubContent.support_contact_us_text,
            color: colors.LEARNERS,
            panelContent: <RvsSupportContactUs />,
            skipA11yPanelHeading: true
          }
        ]}
      />
      <MyProfileEditPanel isOpen={editAccountModalOpen} closePanel={closeEditAccountModal} />
    </div>
  );
}

export default compose(
  withLocalizedContent('hubGlossary'),
  connect(
    ({ hubUi: { editAccountModalOpen } }) => ({
      editAccountModalOpen
    }),
    {
      closeEditAccountModal: closeEditMyAccountModal
    }
  )
)(RvsSupportPage);

RvsSupportPage.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  editAccountModalOpen: PropTypes.bool.isRequired,
  closeEditAccountModal: PropTypes.func.isRequired
};
