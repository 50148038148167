const RvsSupportConstants = () => [
  {
    title: 'Getting started',
    title_es: 'Empezando',
    showSection: true,
    links: [
      {
        name: 'What is the Results Verification Service?',
        name_es: '¿Qué es el Servicio de Verificación de Resultados?',
        path: '/support/articles/what-is-the-results-verification-service'
      },
      {
        name: 'Which certificates can I verify with the Results Verification Service?',
        name_es: '¿Qué certificados puedo verificar con el Servicio de Verificación de Resultados?',
        path: '/support/articles/certificates-that-can-be-verified'
      },
      {
        name: 'How can I verify a new Oxford Test of English certificate with the Results Verification Service?',
        name_es:
          '¿Cómo puedo verificar un certificado nuevo del Oxford Test of English con el Servicio de Verificación de Resultados?',
        path: '/support/articles/verifying-new-certificates'
      },
      {
        name: 'How can I verify an older Oxford Test of English certificate?',
        name_es: '¿Cómo puedo verificar un certificado antiguo del Oxford Test of English?',
        path: '/support/articles/verifying-old-certificates'
      },
      {
        name: 'Can OUP tell a third-party verifier that a certificate is authentic?',
        name_es: '¿Puede OUP indicar a un verificador externo que un certificado es auténtico?',
        path: '/support/articles/informing-verifiers-whether-a-certificate-is-authentic'
      },
      {
        name: 'How can I download a record of verification?',
        name_es: '¿Cómo puedo descargar un acta de verificación?',
        path: '/support/articles/downloading-a-record'
      },
      {
        name: 'Having trouble checking certificates?',
        name_es: '¿Tiene problemas en la comprobación de certificados?',
        path: '/support/articles/having-trouble-checking-certificates'
      }
    ]
  }
];

export default RvsSupportConstants;
