import SessionStorage from '../storage/Session';

function getCurrentDate() {
  return new Date()
    .toJSON()
    .slice(0, 10)
    .replace(/-/g, '/');
}

export function hasSurpassedInvalidLimit() {
  const persistentStorage = new SessionStorage('failedRequestsCounter');

  if (!persistentStorage.exists()) {
    const data = { counter: 1, date: getCurrentDate() };
    persistentStorage.set(data);
    return false;
  }

  let data = persistentStorage.get();

  if (data.date !== getCurrentDate()) {
    data = { counter: 1, date: getCurrentDate() };
    persistentStorage.set(data);
    return false;
  }

  if (data.counter >= 3) {
    data = { counter: 0, date: getCurrentDate() };
    persistentStorage.set(data);
    return true;
  }

  data.counter += 1;
  persistentStorage.set(data);
  return false;
}

export function resetInvalidLimit() {
  const persistentStorage = new SessionStorage('failedRequestsCounter');
  persistentStorage.clear();
}
