import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { RECAPTCHA_ACTIONS } from '@oup/shared-node-browser/constants';
import FileUploadInput from '../FileUploadInput/FileUploadInput';
import actions from '../../redux/actions';
import { RvsLayoutConstants } from '../../globals/rvsConstants';
import PersistentStorage from '../../utils/storage/Persistent';
import PopoutPanelIconHeading, {
  types as popoutPanelIconHeadingTypes
} from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import config from '../../utils/certificatePdfProcess/config/config';

import styles from './CertificateUpload.scss';

import withLocalizedContent from '../../language/withLocalizedContent';
import { hasSurpassedInvalidLimit, resetInvalidLimit } from '../../utils/rvs/failedRequestsCounter';
import forceAcceptBanner from '../../utils/cookieBanner/forceAcceptBanner';
import { getCurrentPlatform } from '../../utils/platform';

function CertificateUpload({
  submitting,
  success,
  invalid,
  error,
  serviceUnavailable,
  submit,
  reset,
  localizedContent: { certificateUpload: content },
  googleReCaptchaProps
}) {
  let pdfError = null;

  const history = useHistory();
  const navigate = url => {
    history.push(url);
  };

  if (!submitting) {
    if (serviceUnavailable) {
      navigate(RvsLayoutConstants.PATH_NAMES.ERROR_PAGE);
    }
    if (success) {
      resetInvalidLimit();

      const redirectLocationStorage = new PersistentStorage('redirectLocation');
      redirectLocationStorage.set('valid');

      navigate(RvsLayoutConstants.PATH_NAMES.VALID_CERTIFICATE_PAGE);

      reset();
    }
    if (invalid) {
      if (hasSurpassedInvalidLimit()) {
        navigate(RvsLayoutConstants.PATH_NAMES.SUPPORT_MESSAGE_PAGE);
      } else {
        const redirectLocationStorage = new PersistentStorage('redirectLocation');
        redirectLocationStorage.set('invalid');

        navigate(RvsLayoutConstants.PATH_NAMES.INVALID_CERTIFICATE_PAGE);

        reset();
      }
    }
    if (error) {
      const uploadErrorPersistentStorage = new PersistentStorage('rvsCertificateUploadError');
      const uploadError = uploadErrorPersistentStorage.get();
      const botStorage = new PersistentStorage('rvsErrorBot');

      switch (uploadError) {
        case config.errors.INVALID_PDF:
          pdfError = { title: content.invalid_pdf_title, subtitle: content.invalid_pdf_subtitle };
          break;
        case config.errors.FILE_NOT_PDF:
          pdfError = { title: content.file_not_pdf_title, subtitle: content.file_not_pdf_subtitle };
          break;
        case config.errors.LEGACY_CERTIFICATE:
          pdfError = { title: content.legacy_certificate_title, subtitle: content.legacy_certificate_subtitle };
          break;
        case config.errors.MODULE_REPORT_PDF:
          pdfError = { title: content.module_report_title, subtitle: content.module_report_subtitle };
          break;
        case config.errors.ALTERED_PDF:
          pdfError = { title: content.altered_pdf_title, subtitle: content.altered_pdf_subtitle };
          break;
        case config.errors.IS_BOT:
          botStorage.set(true);
          navigate(RvsLayoutConstants.PATH_NAMES.ERROR_PAGE);
          break;
        case config.errors.DATE_TAKEN_BEFORE_JULY_2023:
          pdfError = {
            subtitle: content.date_taken_before_july_2023_subtitle
          };
          break;
        default:
          pdfError = { title: content.generic_error_title, subtitle: content.generic_error_subtitle };
      }
    }
  }

  const fileHandler = async file => {
    forceAcceptBanner();
    const data = new FormData();
    data.append('file', file);
    const token = await googleReCaptchaProps.executeRecaptcha(RECAPTCHA_ACTIONS.UPLOAD_CERTIFICATE);
    data.append(
      'recaptcha',
      JSON.stringify({
        reCAPTCHAToken: token,
        reCAPTCHATokenSource: getCurrentPlatform()
      })
    );
    submit(data);
  };

  const retry = () => {
    reset();
  };

  const fileTypes = ['.pdf'];

  return (
    <div className={styles.CertificateUploadContainer}>
      {!submitting && !success && !invalid && (
        <div className={styles.DropZoneContainer}>
          <FileUploadInput
            id="certificateUploadInput"
            label={content.drag_file_label}
            buttonText={content.browse_file_button}
            supportedFileTypes={fileTypes}
            handleFile={fileHandler}
            orLabel={content.or_label}
            notSupportedMessage={content.file_not_pdf_title}
            notSupportedMessageLine2={content.file_not_pdf_subtitle}
            chooseDifferentMessage={content.choose_different_label}
            retryMessage={content.retry_label}
            externalError={pdfError}
            retry={retry}
            defaultCustomClass="certificateUpload"
            errorCustomClass="certificateUploadError"
          />
        </div>
      )}
      {(submitting || success || invalid) && <PopoutPanelIconHeading type={popoutPanelIconHeadingTypes.LOADING} />}
    </div>
  );
}

CertificateUpload.propTypes = {
  submitting: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  serviceUnavailable: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired,
  googleReCaptchaProps: PropTypes.object.isRequired
};

export default compose(
  withLocalizedContent('certificateUpload'),
  connect(
    state => {
      const {
        rvs: {
          upload: { submitting, success, invalid, error, serviceUnavailable }
        }
      } = state;

      return { submitting, success, invalid, error, serviceUnavailable };
    },
    dispatch => ({
      submit: input => {
        dispatch(actions.rvsUploadSubmit(input));
      },
      reset: () => {
        dispatch(actions.rvsUploadReset());
      }
    })
  )
)(withGoogleReCaptcha(CertificateUpload));
