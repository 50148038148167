import React, { Component } from 'react';
import styles from './RvsTopRow.scss';

import cmsContent from '../../../../utils/cmsContent.js';

export default class RvsTopRow extends Component {
  constructor() {
    super();
    this.state = { CMS: {} };
  }

  componentWillMount() {
    this.setState({ CMS: cmsContent.rvsHomepage || {} });
  }

  componentWillReceiveProps() {
    this.setState({ CMS: cmsContent.rvsHomepage || {} });
  }

  render() {
    const { CMS } = this.state;

    return (
      <div className={styles.RvsHomeTopRow}>
        <div className={styles.heroContainer}>
          <div className={styles.heroContent}>
            <h1>
              {CMS.hero_description_0} <br aria-hidden="true" /> {CMS.hero_description_1}
            </h1>
            <h2>
              {CMS.hero_description_2} <br aria-hidden="true" /> {CMS.hero_description_3}
            </h2>
          </div>
          <img className={styles.heroImage} src={CMS.hero_image_src} alt={CMS.alt_image_hero} />
        </div>
      </div>
    );
  }
}
