/* eslint-disable no-return-assign */
import React, { useEffect, useRef } from 'react';

// The following code is based on the script provided by Comm100 from the administrator panel
const siteId = 1000332;
const codePlan = '318f5108-b81c-4aad-a4d2-931e4452b9de';
const divId = 'comm100-button-318f5108-b81c-4aad-a4d2-931e4452b9de';

function RvsSpanishChat() {
  const comm100Ref = useRef(null);

  function loadExternalScript(url) {
    const scriptElm = document.createElement('script');
    scriptElm.type = 'text/javascript';
    scriptElm.async = true;
    scriptElm.src = url + window.Comm100API.site_id;
    const scriptElmSibling = document.getElementsByTagName('script')[0];
    scriptElmSibling.parentNode.insertBefore(scriptElm, scriptElmSibling);
  }

  const atachScript = () => {
    const Comm100API = {
      site_id: siteId,
      main_code_plan: codePlan,
      chat_buttons: [
        {
          code_plan: codePlan,
          div_id: divId
        }
      ]
    };
    window.Comm100API = Comm100API;
    loadExternalScript('https://vue.comm100.com/livechat.ashx?siteId=');
    setTimeout(() => {
      if (!Comm100API.loaded) loadExternalScript('https://standby.comm100vue.com/livechat.ashx?siteId=');
    }, 5000);
  };

  useEffect(() => {
    if (!window.Comm100API) {
      atachScript();
    } else {
      window.Comm100API.set('livechat.button.isVisible', true);
    }
    return () => {
      window.Comm100API.do('livechat.chat.close');
      window.Comm100API.set('livechat.button.isVisible', false);
    };
  }, []);

  return <div id={divId} ref={comm100Ref} />;
}

export default RvsSpanishChat;
