import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';
import styles from './InvalidCertificatePage.scss';
import { RvsLayoutConstants } from '../../../globals/rvsConstants';

import withLocalizedContent from '../../../language/withLocalizedContent';
import { readInvalidCertChannel, addToRetryChannel } from '../../../redux/sagas/utils/rvs/certificateChannels';

import Button, { buttonTypes } from '../../../components/Button/Button';
import { Action } from '../../../components/ActionList/ActionList';
import RvsForm from '../../../components/RvsForm/RvsForm';
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import PdfExporter from '../PdfExporter/PdfExporter';
import { getLanguage } from '../../../utils/manageSelectorLanguage';

function InvalidCertificatePage({ localizedContent: { invalidCertificatePage: content, rvsFormPage: formContent } }) {
  const [formData, setFormData] = useState();
  const history = useHistory();

  const navigate = url => {
    history.push(url);
  };

  useEffect(() => {
    const certData = readInvalidCertChannel();
    if (!certData) {
      navigate(RvsLayoutConstants.PATH_NAMES.HOME_PAGE);
    } else {
      setFormData(certData);
    }
  }, []);

  const retryCertificate = () => {
    addToRetryChannel(formData);
    navigate(RvsLayoutConstants.PATH_NAMES.MANUAL_FORM);
  };

  if (!formData) {
    return false;
  }

  return (
    <div>
      <div className={styles.messageContainer}>
        <Helmet title={content.invalid_certificate_head} />
        <div id="messageContainer">
          <SVGIcon glyph={GLYPHS.ICON_ERROR_CIRCLE} />
          <h1>{content.error_text}</h1>
          <div className={styles.explainText}>{content.explain_text}</div>
        </div>
        <div className={styles.actions}>
          <Action className={styles.editButton} back label={content.edit_button} onClick={retryCertificate} />
          <Button
            type={buttonTypes.PRIMARY}
            text={content.check_certificate_button}
            to={RvsLayoutConstants.PATH_NAMES.HOME_PAGE}
          />
          <Button
            customClassName={styles.downloadButton}
            type={buttonTypes.DOWNLOAD}
            text={content.download_button}
            glyph={GLYPHS.ICON_DOWNLOAD}
            hidePostIcon
            onClick={() => {
              const currentLanguage = getLanguage();
              PdfExporter(content, formContent, formData, currentLanguage, 'invalid');
            }}
          />
        </div>
      </div>
      {formData && <RvsForm data={formData} customClassName={styles.form} readOnly displayBodyOnly />}
    </div>
  );
}

InvalidCertificatePage.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default compose(withLocalizedContent('invalidCertificatePage', 'rvsFormPage'))(InvalidCertificatePage);
